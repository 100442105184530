<template>
  <div class="team-invite">
    <social-auth-loader :labels="labels" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getUrlParam } from "@/utils/url";
import { SocialAuthLoader } from "@/components";
// import { NEW_CHANNEL, FACEBOOK_PAGES } from "@/utils/constants";

export default {
  name: "Invite",
  components: { SocialAuthLoader },
  data() {
    return {
      labels: ["Please wait....", "Confirming team invitation"],
    };
  },
  computed: {
    ...mapState({
      isConfirming: (state) => state.team.status.requesting.confirmTeamInvite,
    }),
  },
  async created() {
    const params = getUrlParam(window.location.href);
    if (params.token) {
      const payload = { ...params };
      this.confirmTeamInvite(payload)
        .then((res) => {
          this.$router.push("/");
        })
        .catch((err) => {
          this.$router.push("/");
        });
    }
  },
  methods: {
    ...mapActions({
      confirmTeamInvite: "confirmTeamInvite",
    }),
  },
};
</script>

<style lang="scss" scoped>
.team-invite {
  height: 100vh;
}
</style>
